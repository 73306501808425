// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbo").start()
import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("channels")
require("jquery")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.addEventListener('turbo:load', function() {
  console.log('Ready');

  // Update Points for Race Positions

  function updatePoints() {

    let getValue = this.value;
  
    let points = 0;

    const pointsInput = this.parentElement.parentElement.nextElementSibling.lastElementChild.lastElementChild;

    switch(getValue) {
      case '1': 
        points = 100;
        break;
      case '2':
        points = 85;
        break;
      case '3':
        points = 75;
        break;
       case '4':
        points = 65;
        break;
      case '5':
        points = 60;
        break;
      case '6':
        points = 55;
        break;
       case '7':
        points = 50;
        break;
      case '8':
        points = 45;
        break;
      case '9':
        points = 40;
        break;
       case '10':
        points = 35;
        break;
      case '11':
        points = 30;
        break;
      case '12':
        points = 28;
        break;
       case '13':
        points = 26;
        break;
      case '14':
        points = 24;
        break;
      case '15':
        points = 22;
        break;
       case '16':
        points = 20;
        break;
      case '17': 
        points = 18;
        break;
      case '18':
        points = 16;
        break;
      case '19':
        points = 14;
        break;
       case '20':
        points = 12;
        break;
      case '21':
        points = 10;
        break;
      case '22':
        points = 9;
        break;
       case '23':
        points = 8;
        break;
      case '24':
        points = 7;
        break;
      case '25':
        points = 6;
        break;
       case '26':
        points = 5;
        break;
      case '27':
        points = 4;
        break;
      case '28':
        points = 3;
        break;
       case '29':
        points = 2;
        break;
      case '30':
        points = 1;
        break;
      default:
        points = 1;
    }

    pointsInput.value = points;

  }

  document.querySelectorAll('.position_update').forEach(i => 
    i.addEventListener('change', updatePoints)
  );

});

$(document).on('ready turbo:load', function() {

  // Alert Dismiss
  $('.alert').on('click', 'button', function(e){
    $(this).parent().hide();
  });

  // Dashboard Mobile Nav
  $('.dashboard_nav_toggle').on('click', function(e){
    e.preventDefault();
    $(this).toggleClass('dashboard_nav_toggle--active');
    $('#dashboard_nav').toggleClass('show');
  });

  // JS Sorting Tables

  /*
   * @param {HTMLTableElement} table The table to sort
   * @param {number} column The index of the column to sort
   * @param {boolean} asc Determines if the sorting will be in ascending
  */

   function sortTableByColumn(table, column, asc = true) {
    const dirModifier = asc ? 1 : -1;
    const tBody = table.tBodies[0];
    const rows = Array.from(tBody.querySelectorAll("tr"));

    // Sort each row
    const sortedRows = rows.sort((a, b) => {
        let aColText = a.querySelector(`td:nth-child(${ column + 1 })`).textContent.trim();
        let bColText = b.querySelector(`td:nth-child(${ column + 1 })`).textContent.trim();

        if (!isNaN(parseFloat(aColText)) && !isNaN(parseFloat(bColText))) {
          aColText = parseFloat(aColText)
          bColText = parseFloat(bColText)
        }

        return aColText > bColText ? (1 * dirModifier) : (-1 * dirModifier);
    });

    // Remove all existing TRs from the table
    while (tBody.firstChild) {
        tBody.removeChild(tBody.firstChild);
    }

    // Re-add the newly sorted rows
    tBody.append(...sortedRows);

    // Remember how the column is currently sorted
    table.querySelectorAll("th").forEach(th => th.classList.remove("sortable_table__asc", "sortable_table__desc"));
    table.querySelector(`th:nth-child(${ column + 1})`).classList.toggle("sortable_table__asc", asc);
    table.querySelector(`th:nth-child(${ column + 1})`).classList.toggle("sortable_table__desc", !asc);
  }

  document.querySelectorAll(".js_sortable_table th").forEach(headerCell => {
    headerCell.addEventListener("click", () => {
        const tableElement = headerCell.parentElement.parentElement.parentElement;
        const headerIndex = Array.prototype.indexOf.call(headerCell.parentElement.children, headerCell);
        const currentIsAscending = headerCell.classList.contains("sortable_table__asc");

        sortTableByColumn(tableElement, headerIndex, !currentIsAscending);
    });
  });

  // Responsive Tables

  $( ".responsive_table" ).scroll(function() {
    if($('.responsive_table').scrollLeft() > 1) {
      $(this).addClass('responsive_table--scrolled');
    } else {
      $(this).removeClass('responsive_table--scrolled');
    }
  });

  // Table filters

  $('.filter_female').hide();
  $('.table_filter__option--male').addClass('table_filter__option--active');

  $('.table_filter__option').on('click', function(){
    if ($(this).hasClass('table_filter__option--male')) {
      $('.filter_female').hide();
      $('.filter_male').show();
      $(this).siblings().removeClass('table_filter__option--active');
      $(this).addClass('table_filter__option--active');
    } else {
      $('.filter_female').show();
      $('.filter_male').hide();
      $(this).siblings().removeClass('table_filter__option--active');
      $(this).addClass('table_filter__option--active');
    }

  });

});